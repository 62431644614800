
import { PlusIcon, TrashIcon } from '@heroicons/vue/outline';
import { ref, watch } from 'vue';
import {
  Switch,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from '@headlessui/vue';
import { XCircleIcon } from '@heroicons/vue/solid';
import store from '@/store';
import { AirlinePermission } from '@/models';
import {
  getIntegrationPermissions,
  updateIntegrationPermission,
} from '@/api/integrations.api';
import { getCallbackSubscriptions, disableCallback } from '@/api/callbacks.api';
import toCamelCase from '@/utils/toCamelCase';
export default {
  name: 'OrganizationIntegrations',
  components: {
    Switch,
    XCircleIcon,
    PlusIcon,
    TrashIcon,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup() {
    const sharedState = store.state;
    const permissions = ref({});
    const permissionsError = ref('');
    const permissionsLoading = ref(false);
    const permissionsCanEdit = ref(false);
    const callbackSubscriptionsLoading = ref(false);
    const callbackSubscriptionsError = ref('');
    const callbackSubscriptions = ref({});
    const callbackSubscriptionDisableError = ref('');

    const fetchPermissions = async () => {
      permissionsLoading.value = true;
      permissionsError.value = '';
      try {
        const response = await getIntegrationPermissions(
          sharedState.integration.id
        );
        const transformedPermissions: { [id: string]: any } = {};
        for (let i = 0; i < response.data.permissions.length; i++) {
          const permission = response.data.permissions[i];
          if (!(permission.airlineId in transformedPermissions))
            transformedPermissions[permission.airlineId] = {};
          transformedPermissions[permission.airlineId][permission.productType] =
            {
              id: permission.id,
              airlineId: permission.airlineId,
              enabled: permission.enabled,
              partnerEnabled: permission.partnerEnabled,
            };
        }
        permissions.value = toCamelCase(transformedPermissions);
        permissionsCanEdit.value = response.data?.canEdit;
      } catch (e) {
        permissions.value = [];
        permissionsError.value = `Error fetching permissions`;
      }
      permissionsLoading.value = false;
    };

    const fetchCallbackSubscriptions = async () => {
      callbackSubscriptionsLoading.value = true;
      callbackSubscriptionsError.value = '';
      try {
        const response = await getCallbackSubscriptions(
          sharedState.integration.id
        );
        const transformedCallbacks: {
          deactivatedAt: string;
          deliveryMethod: string;
          eventName: string;
          id: string;
          target: string;
        }[] = [];
        for (let i = 0; i < response.data.callbackSubscriptions.length; i++) {
          const callback = response.data.callbackSubscriptions[i];
          const transformedCallback = {
            deactivatedAt: callback.deactivatedAt,
            deliveryMethod: callback.deliveryMethod,
            eventName: callback.eventName,
            id: callback.id,
            target: callback.target,
          };
          if (transformedCallback.deactivatedAt == null) {
            transformedCallbacks.push(transformedCallback);
          }
        }
        callbackSubscriptions.value = transformedCallbacks;
      } catch (e) {
        callbackSubscriptions.value = [];
        callbackSubscriptionsError.value = `Error fetching callback subscriptions`;
      }
      callbackSubscriptionsLoading.value = false;
    };

    const callbackSubscriptionId = ref('');

    const removeCallback = async () => {
      try {
        const callbackToDisable = callbackSubscriptionId.value;
        await disableCallback(sharedState.integration.id, callbackToDisable);
        fetchCallbackSubscriptions();
      } catch (e) {
        callbackSubscriptionDisableError.value =
          'Something went wrong disabling the callback';
      } finally {
        closeModal();
      }
    };

    const toggleAirlinePermission = async (
      event: any,
      permission: AirlinePermission
    ) => {
      permissionsLoading.value = true;
      permissionsError.value = '';
      try {
        await updateIntegrationPermission(
          permission.id,
          permission.partnerEnabled
        );
      } catch (e) {
        permission.partnerEnabled = !permission.partnerEnabled;
        permissionsError.value = `Error updating permission`;
      }
      permissionsLoading.value = false;
    };

    watch(
      () => store.state.integration,
      (oldValue, newValue) => {
        fetchPermissions();
      }
    );

    const isOpen = ref(false);
    const closeModal = () => {
      isOpen.value = false;
    };

    const openModal = (selectedCallbackSubscriptionId: string) => {
      isOpen.value = true;
      callbackSubscriptionId.value = selectedCallbackSubscriptionId;
    };

    fetchPermissions();
    fetchCallbackSubscriptions();

    return {
      permissions,
      permissionsLoading,
      permissionsError,
      toggleAirlinePermission,
      permissionsCanEdit,
      callbackSubscriptions,
      callbackSubscriptionsLoading,
      callbackSubscriptionsError,
      callbackSubscriptionDisableError,
      isOpen,
      closeModal,
      openModal,
      removeCallback,
    };
  },
};
